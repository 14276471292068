import { useStaticQuery, graphql } from "gatsby"

function useInstagramPosts() {
  const { allInstaNode } = useStaticQuery(
    graphql`
      {
        allInstaNode(sort: { fields: timestamp, order: DESC }, limit: 10) {
          edges {
            node {
              id
              caption
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, aspectRatio: 1)
                }
              }
            }
          }
        }
      }
    `
  )

  return allInstaNode.edges
}

export default useInstagramPosts
