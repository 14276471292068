import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import { IconContext } from "react-icons"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im"

import SwiperCore, { Navigation, Autoplay } from "swiper/core"

// install Swiper modules
SwiperCore.use([Navigation, Autoplay])

type TestimonialProps = {
  children: JSX.Element,
  image: string,
  author: string
}

function Testimonial({ children, image, author }: TestimonialProps) {
  return (
    <div className="w-full">
      <div className="hidden md:block md:w-2/5 lg:w-auto md:absolute lg:static">
        <img
          className="relative z-10 ml-10 grayscale filter max-w-full"
          src={image}
          width={450}
        />
      </div>
      <div className="w-full lg:absolute lg:bottom-0 top-0 flex items-center">
        <div
          className="flex justify-end py-14 px-10 w-full"
          style={{
            backgroundImage: `url(${
              require("../../images/bg/testimonial-bg.jpg").default
            })`,
          }}
        >
          <div className="md:w-1/2 relative">
            <IconContext.Provider
              value={{
                className:
                  "absolute text-2xl -top-10 opacity-50 text-barely-visible",
              }}
            >
              <ImQuotesLeft />
            </IconContext.Provider>
            {children}
            <IconContext.Provider
              value={{
                className:
                  "absolute text-2xl right-0 opacity-50 text-barely-visible",
              }}
            >
              <ImQuotesRight />
            </IconContext.Provider>

            <span className="block pt-8 font-bold">{author}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

function Testimonials() {
  return (
    <div className="flex items-center">
      <button className="testimonial-prev border border-white p-2 mr-4 transition-colors hover:bg-white hover:text-black">
        <BsChevronLeft />
      </button>
      <Swiper
        className="testimonial-slider"
        slidesPerView={1}
        navigation={{
          prevEl: ".testimonial-prev",
          nextEl: ".testimonial-next",
        }}
        loop
        autoplay
        autoHeight
      >
        <SwiperSlide>
          <Testimonial
            image={
              require("../../images/testimonials/home-testimonial-alessandra.jpg")
                .default
            }
            author="Alessandra Schladetsch"
          >
            <p>
              Thank you to Ryan and his team for a great job on my apartment
              with painting walls, doors and ceilings and installing skirting
              boards in my apartment. Ryan was always easily contactable, always
              on time, extremely efficient in the works completed and even
              completed additional works in my kitchen that I hadn't even
              considered which made a massive improvement to the property. The
              improvements to my apartment from the work by Salt Property
              Maintenance are massive and I would recommend Ryan and Salt
              Property Maintenance to everyone!
            </p>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image={
              require("../../images/testimonials/13071969_10208286005800072_5156529709577657469_o-450x520.jpg")
                .default
            }
            author="Jane Dewitai"
          >
            <p>
              Ryan hard at work repairing my ceiling after one absent minded
              person 'me'....forgot she had the bath running...Ryan and his team
              worked their magic with the least amount of disruption and all was
              done and dusted by the end of day 4. Thanks for making that happen
              guys.
            </p>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image={
              require("../../images/testimonials/13209_10153306364881178_5167619278818447763_n-450x520.jpg")
                .default
            }
            author="Rikki Jaye Ballantyneai"
          >
            <p>
              Salt property maintenance saved me thousands $$ by safely removing
              my asbestos fence and then built a new timber fence - looks 100%
              better!!
            </p>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image={
              require("../../images/testimonials/giverny-parker-450x520.jpg")
                .default
            }
            author="Giverny Parker"
          >
            <p>
              Salt Property Maintenance have just completed a front entrance
              deck/stairs reno on my house in Tugun. I am so grateful that a
              friend recommended them to me, and will continue to use them for
              all of my home maintenance jobs (of which there are a lot!) in the
              future!
              <br />
              Ryan and his team are so eager and enthusiastic in the work they
              carry out. They are highly motivated, have a great work ethic,
              great attention to detail, and complete their work to very high
              standards.
              <br />
              My property is full of hidden surprises, and no issue was too big
              for them to handle! Ryan communicated with me through the whole
              process, let me know of any issues to resolve, sent me daily
              updates/photos, and always got back to me in a timely manner.
              <br />
              Overall, I highly recommend them to you!
            </p>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image={
              require("../../images/testimonials/andrew-450x520.jpg").default
            }
            author="Andrew Beshara"
          >
            <p>
              Ryan and his team did a great job with the deck. It came up
              looking better than when it was built!! They also weren't deterred
              when asked to complete small jobs. Love your work and will
              certainly call you for any future work.
            </p>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image={
              require("../../images/testimonials/Kylie-Wilkinson-450x520.jpg")
                .default
            }
            author="Kylie Wilkinson"
          >
            <p>
              Thanks Ryan, we're thrilled with the work you've done at our
              place. You'll be our first call when we're ready to take on the
              next reno job.
            </p>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image={
              require("../../images/testimonials/Dave-thompson--450x520.jpg")
                .default
            }
            author="Dave Thompson"
          >
            <p>
              Just had Ryan and Eddie design and construct our new timber front
              fence. Great job done, punctual and professional. Will be using
              them again.
            </p>
          </Testimonial>
        </SwiperSlide>
        <SwiperSlide>
          <Testimonial
            image={
              require("../../images/testimonials/stayc-450x520.jpg").default
            }
            author="Stayc Sinclair"
          >
            <p>
              Where do I even start!?...
              <br />
              From the moment my unit was quoted to its amazing transformation -
              Salt Property and Maintenance made renovating a breeze.
              <br />
              Ryan was flexible in the work hours he could do for me, always
              contactable and competitively priced everything within my budget.
              No job was too big or too small - from decking & flooring to
              fitting a new garden hose. Yew.
              <br />
              Such an epic bunch of guys - who went above and beyond my
              expectations and even watered my plants!
              <br />I wouldn't hesitate to recommend these guys to anyone -
              you're dealing with true professionals and genuine people. I will
              continue to use their service until my house is a sparkly new
              mansion. THANK-YOU!!!
            </p>
          </Testimonial>
        </SwiperSlide>
      </Swiper>
      <button className="testimonial-next border border-white p-2 ml-4 transition-colors hover:bg-white hover:text-black">
        <BsChevronRight />
      </button>
    </div>
  )
}

export default Testimonials
