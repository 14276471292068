import * as React from "react"
import ReCAPTCHA from "react-google-recaptcha"

function ContactForm() {
  const [sending, setSending] = React.useState<boolean>(false)
  const [sent, setSent] = React.useState<boolean>(false)
  const [formMessage, setFormMessage] = React.useState<string>("")
  const form = React.useRef<HTMLFormElement>(null)

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    setSending(true)

    const formData = new FormData(form.current!)
    const xhr = new XMLHttpRequest()

    xhr.open("POST", form.current.action!, true)
    xhr.send(formData)
    xhr.onload = function () {
      if (xhr.status === 200) {
        setFormMessage("Your submission was sent! Thank you.")
        form.current?.reset()
        setSent(true)
        setSending(false)
      } else {
        setSent(false)
        setSending(false)
        setFormMessage(
          "There was an error sending your message. Please try again later"
        )
      }
    }
  }

  return !sent ? (
    <form
      action="https://usebasin.com/f/9ba5a56db435"
      method="post"
      ref={form}
      onSubmit={handleSubmit}
    >
      <input
        name="customer-subject"
        size={40}
        className="hidden"
        id="customerSubject"
        aria-invalid="false"
      />

      <input
        name={"name"}
        type="text"
        placeholder="NAME"
        className="contact-form-input mb-8"
        required
      />
      <input
        name={"phone"}
        type="phone"
        placeholder="PHONE"
        className="contact-form-input mb-8"
      />
      <input
        name={"email"}
        type="email"
        placeholder="EMAIL"
        className="contact-form-input mb-8"
      />

      <textarea
        rows={5}
        name={"message"}
        className="contact-form-textarea mb-8"
        placeholder="MESSAGE"
        required
      ></textarea>

      <div className="mb-8">
        <ReCAPTCHA
          sitekey="6Lew3SMUAAAAAJ82QoS7gqOTkRI_dhYrFy1f7Sqy"
          theme="dark"
        />
      </div>

      <input
        type="submit"
        className="bg-transparent cursor-pointer hover:text-secondary transition-colors uppercase text-white font-bold"
        value={sending ? "Sending..." : "Send Now"}
      />

      {formMessage && <p className={"text-white"}>{formMessage}</p>}
    </form>
  ) : <div className={"pt-5 lg:pt-20 pb-10"}>
      <p className={"font-semibold text-white"}>{formMessage}</p>
    </div>
}

export default ContactForm
