import * as React from "react"
import AnimateOnScroll from "../AnimateOnScroll/AnimateOnScroll"

type ServiceListProps = {
  className?: string,
}

function ServiceList({ className }: ServiceListProps) {
  return (
    <AnimateOnScroll transition="fade-in">
      <div className={`md:flex w-full md:w-auto justify-between ${className}`}>
        <Service
          heading={<RectificationsServiceHeading />}
          list={[
            "Carpentry",
            "Electrical",
            "Doors",
            "Windows",
            "Fencing",
            "Flooring",
          ]}
        />

        <Service
          heading={<RenovationsServiceHeading />}
          list={[
            "Bathrooms",
            "Kitchens",
            "Flooring",
            "Painting",
            "Decking",
            "Paving",
          ]}
        />

        <Service
          heading={<BuildingServiceHeading />}
          list={[
            "Partitions",
            "Demolition",
            "Roofing",
            "Retail",
            "Excavations",
            "Plastering",
          ]}
        />
      </div>
    </AnimateOnScroll>
  )
}

function Service({ heading, list }) {
  return (
    <div>
      {heading}

      <p className='hidden md:flex flex-col pl-service-offset'>
        {
          list.map(function(item, i) {
            return (
              <span className='text-barely-visible font-semibold' key={`service-${item}-${i}`}>{item}</span>
            )
          })
        }
      </p>
    </div>
  )
}

function ServiceHeading({ icon, title }) {
  return (
    <div className='flex flex-row-reverse md:flex-row justify-between md:justify-start items-center pb-10'>
      <div className='pr-4'><img alt={`${title}-icon`} src={icon} /></div>
      <div><h3 className='text-2xl'>{ title }</h3></div>
    </div>
  )
}

function RectificationsServiceHeading() {
  return <ServiceHeading
            icon={require('../../images/icons/icon-tools.png').default}
            title='Rectifications'
          />
}

function RenovationsServiceHeading() {
  return <ServiceHeading
            icon={require('../../images/icons/icon-home.png').default}
            title='Renovations'
          />
}

function BuildingServiceHeading() {
  return <ServiceHeading
            icon={require('../../images/icons/icon-measure.png').default}
            title='Building'
          />
}

export default ServiceList;
