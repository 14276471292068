import * as React from 'react'
import useInstagramPosts from "../../hooks/useInstagramPosts"
import { GatsbyImage } from "gatsby-plugin-image"
import { SRLWrapper } from "simple-react-lightbox"

type GalleryBlockProps = {
  className?: string,
}

function GalleryBlock({ className }: GalleryBlockProps) {
  const posts = useInstagramPosts() || []

  return (
    <SRLWrapper>
      <div className={`flex flex-wrap ${className}`}>
        {posts.map(function (post, i) {
          return (
            <div
              key={post.node.id}
              className={`w-1/2 md:w-1/5 ${i > 5 ? "hidden md:block" : ""}`}
            >
              <a
                href={
                  post.node.localFile.childImageSharp.gatsbyImageData.images
                    .fallback.src
                }
              >
                <GatsbyImage
                  alt={post.node.caption}
                  image={post.node.localFile.childImageSharp.gatsbyImageData}
                />
              </a>
            </div>
          )
        })}
      </div>
    </SRLWrapper>
  )
}

export default GalleryBlock;
