import * as React from 'react'
import AnimateOnScroll from '../AnimateOnScroll/AnimateOnScroll'

type ImageTextBlockProps = {
  bgImage: string,
  sectionImage: string,
  className?: string,
  imageAlt: string,
  children: JSX.Element | JSX.Element[],
}

function ImageTextBlock({
  bgImage,
  sectionImage,
  className,
  imageAlt,
  children,
}: ImageTextBlockProps) {
  return (
    <div
      className={`p-4 md:p-10 lg:p-20 md:flex relative items-center justify-between ${className}`}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="md:w-2/3 lg:w-5/12">{children}</div>
      <div className="hidden md:block">
        <AnimateOnScroll transition="slide-left">
          <img alt={imageAlt} src={sectionImage} />
        </AnimateOnScroll>
      </div>
    </div>
  )
}

export default ImageTextBlock;
