import * as React from "react"

type LogoProps = {
  className: string,
  width: number
}

function Logo({ width, className }: LogoProps) {
  return (
    <img
      src={require("../../images/logo.svg").default}
      alt="Logo"
      width={width}
      className={className}
    />
  )
}

export default Logo;
