import * as React from 'react'
import ContactForm from '../ContactForm/ContactForm'
import AnimateOnScroll from "../AnimateOnScroll/AnimateOnScroll"

type ContactBlockProps = {
  bgImage: string,
  children: React.ReactNode,
}

function ContactBlock({ bgImage, children }: ContactBlockProps) {
  return (
    <AnimateOnScroll transition="slide-up">
      <div className="md:flex">
        <div
          className="md:w-1/2 px-4 py-10 md:p-20 text-black flex flex-col justify-center bg-cover"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          {children}
        </div>
        <div className="md:w-1/2 px-4 py-10 md:p-20 bg-contact-bg">
          <h3 className="text-4xl mb-10 font-thin">Get In Touch</h3>

          <ContactForm />
        </div>
      </div>
    </AnimateOnScroll>
  )
}

export default ContactBlock;
