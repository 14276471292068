import * as React from "react"
import '../styles/index.css';
import { PageProps, graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import Nav from '../components/Nav/Nav'
import Hero from '../components/Hero/Hero'
import Logo from '../components/Logo/Logo'
import Section from '../components/Section/Section'
import BigHeading from '../components/BigHeading/BigHeading'
import ServiceList from '../components/ServiceList/ServiceList'
import ImageTextBlock from '../components/ImageTextBlock/ImageTextBlock'
import GalleryBlock from '../components/GalleryBlock/GalleryBlock'
import ContactBlock from '../components/ContactBlock/ContactBlock'
import Testimonials from '../components/Testimonials/Testimonials'

function Index(_props: PageProps): React.ReactNode {
  return (
    <Layout className="bg-black text-white">
      <Seo />

      <Hero
        id="welcome"
        backgroundImage={require("../images/bg/home-hero.jpg").default}
      >
        <Nav />
        <Logo width={545} className="px-10 md:px-0 max-w-full" />
        <BigHeading>
          Maintenance | Renovations <br />
          Building | Rectifications
        </BigHeading>
      </Hero>

      <Section id="about">
        <ImageTextBlock
          className="py-20"
          bgImage={require("../images/bg/about-bg.jpg").default}
          imageAlt="Ryan at work"
          sectionImage={
            require("../images/home/home-about-image-1.jpg").default
          }
        >
          <p className="mb-4 leading-relaxed">
            <strong>
              Based on the Gold Coast, Salt Building Group service not only the
              local area but extend to surrounding suburbs &amp; Northern NSW.
            </strong>
          </p>

          <p className="mb-4 leading-relaxed">
            Whether you are renovating, restoring, repairing, building or
            maintaining Salt aim to provide a quality response to all your
            building requirements.
          </p>

          <p className="mb-4 leading-relaxed">
            Salt pride themselves on being considerate and collaborative. They
            work with body corporate’s, real estate agencies, property
            management companies as well as numerous private, commercial and
            domestic entities. There is no need to keep track of each job,
            contractor or purchase as Salt offer an extensive range of services
            under one point of contact.
          </p>

          <p className="mb-4 leading-relaxed">
            Operating all year round, Salt have emergency services available 24
            hours a day, seven days a week. You can access all the support you
            need with Salts friendly team of building &amp; renovating
            specialists.
          </p>
        </ImageTextBlock>
      </Section>

      <Section id="services">
        <ServiceList />
      </Section>

      <Section fullWidth>
        <GalleryBlock />
      </Section>

      <Section id="testimonials">
        <Testimonials />
      </Section>

      <Section id="contact">
        <ContactBlock bgImage={require("../images/bg/contact-bg.jpg").default}>
          <h3 className="text-2xl font-bold mb-4">Email</h3>
          <ul className="mb-8">
            <li>info@saltbuildinggroup.com.au</li>
          </ul>

          <h3 className="text-2xl font-bold mb-4">Phone</h3>
          <ul className="mb-8">
            <li>0405 316 336</li>
          </ul>

          <h3 className="text-2xl font-bold mb-4">Areas Covered</h3>
          <ul className="mb-8">
            <li>Northern Gold Coast</li>
            <li>Southern Gold Coast</li>
            <li>Central Gold Coast</li>
            <li>Tweed Heads</li>
          </ul>
        </ContactBlock>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

export default Index;
